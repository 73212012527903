import React from 'react';
import translate from '../../i18n/translate';
import planStore from "../../store/planStore";

const PaymentStatus = (props) => {
    return (
        <div className="payment-status-wrapper">
            <div className="container">
                <div className="row">
                    <div className={props.error ? "col-xl-11 error-message" : "col-xl-11"}>
                        {props.message1 && translate(props.message1)} {props.message2 && <p>{translate(props.message2)}</p>}
                    </div>
                </div>
                {planStore.orderNo && <div className="order-number">Ref. ID : {planStore.orderNo}</div>}
            </div>
        </div>
    )
}

export default PaymentStatus;